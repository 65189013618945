<script>
import {app} from 'app'
import BookDatetime from 'components/common/BookDatetime'
import BookTable from 'components/common/BookTable'
import BookModal from 'components/common/BookModal'
import BookSelectbox from "../common/BookSelectbox";
import BookLoader from 'components/common/loader/BookLoader'
import Association from 'components/association/Association'
import Feed from 'components/feed/Feed'
import Production from 'components/production/Production'
import System from 'components/system/System'
import Entity from 'components/entity/Entity'
import Item from 'components/common/item/Item'

export default {
    components: {
        BookDatetime,
        BookTable,
        BookModal,
        BookSelectbox,
        BookLoader
    },
    props: {
        disabled: {
            default: false,
            type: Boolean
        },
        disabledMessage: String,
        items: {
            default: () => [],
            type: Array
        },
        parent: {
            default: () => {
                return {}
            }
        },
        type: {
            default: '',
            type: String
        },
        associationType: {
            default: '',
            type: String
        },
        countries: {
            default: () => [],
            type: Array
        },
        channels: {
            default: () => [],
            type: Array
        },
        activityTypes: {
            default: () => [],
            type: Array
        },
        showInSidebar: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            loaded: false,
            addForm: {
                show: false
            },
            leagueEntity: null,
            runTypeEntity: null,
            formData: this.defaultData()
        }
    },
    computed: {
        processing() {
            return this.$loader.has('BookAssociationsProcessing')
        },
        loadingItems() {
            return this.$loader.has('BookAssociationsLoadingItems')
        },
        setting() {
            return this.$settings.get.associations
        },
        getApiUrl(id) {
            return 'api/' + this.type + '/' + this.parent.get.id + '/' + 'association'
        },
        getNENTCalendarApiUrl() {
          return 'v4/calendar/events';
        },
        getItems() {
            if (this.loadingItems) return []
            let _country = this.formData.country.value,
                _type = this.formData.type.value
            return Lazy(this.$store.state.data.association)
            .map(item => {
                Item.parse(item)
                return item
            })
            .filter(item => {
              // Determine if the element, if production, should be displayed
              // or not, based on runType. This is because "Edit productions"
              // should be displayed separately from other productions.
              if (item.elementType === 'production') {
                switch (true) {
                  case (_type === 'edit' && item.productionType !== 'edit'):
                  case (_type === 'production' && item.productionType !== 'production'):
                  case (_type === 'task' && item.productionType !== 'task'):
                    return false
                }
              }

              // Make other checks to determine if the current item should be
              // displayed or not.
              switch (true) {
                case (item.elementType === 'event' && this.getItemLeagueId(item) === this.formData.league.value):
                  return true
                  // events can come with id 0 because they are not in the system never will
                  // on this search/filter because we are getting them from NENT Calendar.
                  // we should only check if they don't have nent_id
                case (item.elementType === 'event' && item.nent_id < 1):
                case (item.id === 0 && item.elementType !== 'event'):
                case (this.items.findIndex(v => v.id === item.id) > -1):
                case ( (this.type === item.elementType) && (this.parent.get.id === item.id) ):
                case (this.formData.league.value && _type !== 'task' &&
                    (item.league !== this.formData.league.value)):
                  return false
              }

              if(_country && _country !== '' && item.elementType !== 'event') {
                if(item.elementType === 'production') {
                  if(item.productionType !== 'task') {
                    let channelEntityItem = this.channels.find(i => i.id === item.channel)
                    if(channelEntityItem && channelEntityItem.country === _country) {
                      return true
                    }
                    else if (channelEntityItem) {
                      let interestArray = channelEntityItem.description ? channelEntityItem.description.split(',') : []
                      // true or false
                      return interestArray.length > 0 && interestArray.findIndex(i => i === _country) > -1;
                    }
                  }
                  else {
                    let activityTypeItem = this.activityTypes.find(i => i.id === item.runType)
                    if (activityTypeItem) {
                      // true or false
                      return activityTypeItem.country === _country || !activityTypeItem.country;
                    } else return false
                  }

                }
                else if (item.elementType === 'feed') {
                  // true or false
                  return !!(item.mcr && item.mcr.findIndex(i => i === _country) > -1);
                }
              }

              return true

            })
                .sortBy(item => item[`${item.elementType}Start`], false)
                .toArray()
        },
        getSelectedType() {
            return this.formData.type.value === 'edit' || this.formData.type.value === 'task' ? 'production' : this.formData.type.value
        },
        getSelectedItem() {
          return this.formData.item
        },
        getAssociationItems() {
            return Lazy(this.items)
                .map(item => {
                    Item.parse(item)
                    return item
                })
                .sortBy(item => item[`${item.elementType}Start`])
                .toArray()
        },
        associationData() {
            const context = this
            const output = {
                options: {
                    actions: ['edit', 'delete'],
                    actionSettings: {
                        edit: {
                            label: 'Open',
                            title: 'Open',
                        },
                        delete: {
                            title: 'Remove this association',
                        }
                    },
                },
                columns: [
                    {
                        value: 'id',
                        label: '',
                        sortable: true,
                        visible: false
                    },
                    {
                        value: 'status',
                        label: 'Status',
                    },
                    {
                        value: 'itemId',
                        label: 'ID',
                        sortable: true,
                        visible: false,
                    },
                    {
                        value: 'type',
                        label: 'Type',
                        sortable: true,
                    },
                    {
                        value: 'title',
                        label: 'Title',
                        sortable: true,
                    },
                    {
                        value: 'time',
                        label: 'Time',
                        sortable: true,
                    },
                    // {
                    //     value: 'start',
                    //     label: 'Start',
                    //     sortable: true,
                    // },
                    // {
                    //     value: 'end',
                    //     label: 'End',
                    //     sortable: true,
                    // }
                ],
                rows: []
            }
            context.getAssociationItems
            .forEach(association => {

                let type = association.elementType.capitalize()
                if(association.productionType) {
                    switch(association.productionType) {
                        case 'edit':
                            type = 'Edit'
                            break
                        case 'task':
                            type = 'Task'
                            break
                    }
                }
                //console.log(association ,' assoc')
                const item = {
                    id: {
                        value: association.associationId
                    },
                    status: {
                        value: '<span class="book-square"></span>',
                        classes: ['book-item-status-' + association.status]
                    },
                    itemId: {
                        value: association.id
                    },
                    type: {
                        value: type
                    },
                    title: {
                        value: association.title
                    },
                    time: {
                        value: this.getItemTime(association)
                    },
                    // start: {
                    //     value: association[`${association.elementType}Start`]
                    // },
                    // end: {
                    //     value: association[`${association.elementType}End`]
                    // },
                    rowOptions: {
                        classes: [],
                        hideActions: []
                    }
                }
                if (this.disabled){
                   item.rowOptions.hideActions.push("delete");
                }
                output.rows.push(item)
            })
            return output
        },
        associationDataForSideBar() {
            const context = this
            const output = {
                options: {
                    actions: ['edit'],
                    actionSettings: {
                        edit: {
                            label: 'Open',
                            title: 'Open',
                        },
                    },
                },
                columns: [
                    {
                        value: 'id',
                        label: '',
                        sortable: true,
                        visible: false
                    },
                    {
                        value: 'itemId',
                        label: 'ID',
                        sortable: true,
                        visible: false,
                    },
                    {
                        value: 'type',
                        label: 'Type',
                        sortable: true,
                    },
                    {
                        value: 'title',
                        label: 'Title',
                        sortable: true,
                    },
                    
                ],
                rows: []
            }
            context.getAssociationItems
            .forEach(association => {

                let type = association.elementType.capitalize()
                if(association.productionType) {
                    switch(association.productionType) {
                        case 'edit':
                            type = 'Edit'
                            break
                        case 'task':
                            type = 'Task'
                            break
                    }
                }
                
                const item = {
                    id: {
                        value: association.associationId
                    },
                    itemId: {
                        value: association.id
                    },
                    type: {
                        value: type
                    },
                    title: {
                        value: association.title
                    },
                    rowOptions: {
                        classes: [],
                        hideActions: []
                    }
                }
                
                output.rows.push(item)
            })
            return output
        }
    },
    watch: {
        'formData.selection.value': function(newValue) {
          if (!this.loaded) return
          if (!this.getItems) return
          if (newValue !== '') {
            Lazy(this.getItems).each((v) => {
              if (v.id === newValue) this.formData.item = v
              if (v.elementType === 'event' && v.nent_id === newValue) this.formData.item = v
            })
          }
        },
        'formData.type.value': function(newValue) {
            if (!this.loaded) return
          if (newValue === 'event') this.loadCalendarItems()
            else this.loadItems()
        },
        'formData.date.value': function(newValue) {
            if (!this.loaded) return
          if (this.getSelectedType === 'event') this.loadCalendarItems()
          else this.loadItems()
        },
        'formData.league.value': function(newValue) {
            if (!this.loaded) return
            this.formData.selection.value = ''
        },
        'formData.country.value': function(newValue, oldValue) {
            if (!this.loaded) return
            if(newValue !== oldValue) {
                this.formData.selection.value = ''
            }
        }
    },
    mounted() {
        this.leagueEntity = new Entity(this.setting.league_entity_id)
        //this.runTypeEntity = new Entity(this.setting.runtype_entity_id)
        if (!this.leagueEntity.get.label /*|| !this.runTypeEntity.get.label*/) {
            let entityIds = [
                this.setting.league_entity_id,
                //this.setting.runtype_entity_id
            ]
            const entity = new Entity()
            entity.all({ids: entityIds.join(',')})
                .then(response => {
                    this.setLoaded()
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the entities.')
                    this.setLoaded()
                })
        }
        else {
            Vue.nextTick(() => {
                this.setLoaded()
            })
        }
    },
    methods: {

        /**
         * Set the component to loaded.
         */
        setLoaded(loaded = true) {
            this.loaded = loaded
        },

        getItemId(item) {
          if (item.elementType === 'event') {
            return item['nent_id']
          }
          return item.id
        },
        getItemLeagueId(item) {
          if (item && item.league_id) {
            let league = this.leagueEntity.get.items.find(i => i.revision === item.league_id);
            if (league) return league.id
          }
        },

        /**
         * Get the item title to be shown in the items list.
         */
        getItemTitle(item) {
          let start, title, channel, id, postponed;
          console.log(item, "association item")
          switch(this.formData.type.value) {
            case 'event':
              let _eventStartDate = item['event_date'] + ' ' + item['event_start']
              let _eventDateToLocal = moment(moment.utc(_eventStartDate).toDate()).format('YYYY-MM-DD HH:mm:ss');
                start = moment(_eventDateToLocal)
                title = item.title
                id = item['nent_id']
                postponed = item.postponed
              break;
            case 'task':
            case 'edit':
              start = moment(item[`productionStart`])
              break;
            default:
                start = moment(item[`${this.formData.type.value}Start`])
                title = item.title
                channel = item.channel ? this.channels.find(i => i.id === item.channel) : null
                id = item.id
          }

            if (postponed) return `(P) ${title} (${id})`
            if (channel) return `${start.format('DD MMM, HH:mm')} ~ ${title} ~ (${channel.label}) ~ (${id})`
            return `${start.format('DD MMM, HH:mm')} ~ ${title} ~ (${id})`
        },


      loadCalendarItems() {
        // Reset the item selection.
        this.formData.selection.value = ''

        return new Promise((resolve, reject) => {
          if (this.loadingItems || !this.formData.type.value || !this.formData.date.value) {
            resolve()
            return
          }

          this.$loader.add('BookAssociationsLoadingItems')

          // Set params for API call.
          const params = {
            from: this.formData.date.value,
            to: this.formData.date.value
          }

          const association = new Association(this.getNENTCalendarApiUrl, 0)

          // Retrieve association items.
          association.all(params, true)
              .then(response => {
                this.$loader.remove('BookAssociationsLoadingItems')
                resolve(response)
              })
              .catch(error => {
                this.$error.set(error, 'It was not possible to load the items.')
                this.$loader.remove('BookAssociationsLoadingItems')
                reject(error)
              })

        })
      },

        /**
         * Load association items from API.
         */
        loadItems() {

            // Reset the item selection.
            this.formData.selection.value = ''

            return new Promise((resolve, reject) => {
                if (this.loadingItems || !this.formData.type.value || !this.formData.date.value) {
                    resolve()
                    return
                }
                this.$loader.add('BookAssociationsLoadingItems')
                const association = new Association(this.getApiUrl, 0)

                // Set params for API call.
                let type = this.getSelectedType
                const params = {
                    type: type,
                    date: this.formData.date.value,
                }

                switch(this.formData.type.value) {
                  case 'event':
                  case 'task':
                  case 'edit':
                    params.productionType = this.formData.type.value.toLowerCase();
                    break
                }

                // Retrieve association items.
                association.all(params, true)
                .then(response => {
                    this.$loader.remove('BookAssociationsLoadingItems')
                    resolve(response)
                })
                .catch(error => {
                    this.$error.set(error, 'It was not possible to load the items.')
                    this.$loader.remove('BookAssociationsLoadingItems')
                    reject(error)
                })

            })
        },

        /**
         * Open/close the add/edit form for Equipment Usage.
         * @param open boolean Tells the function to open/close the form.
         */
        showForm(open = true) {
            if (!open) {
                this.addForm.show = false
            }
            else {
                this.resetForm()
                Vue.nextTick(() => {
                    this.addForm.show = true
                })
            }
        },

        /**
         * Reset the addForm.
         */
        resetForm() {
            this.formData = this.defaultData()
            this.formData.date.value = this.parent.get[`${this.type}Start`]
            this.formData.league.value = this.parent.get.league
        },

        /**
         * Open an associated item in a new tab/window.
         * @param id The item id to open
         */
        openItem(id) {
            const context = this
            const item = this.getAssociationItems.find(v => v.associationId === id)
            if (item.elementType === 'event') {
              this.$alert.set('You can not view events in booking, only at VIAPLAY Sport Calendar System.', 'warning', 5)
              return
            }

            let link_ = {
                destination: context.$route.name,
                view: context.$route.params.view,
                from: context.$route.query.from,
                to: context.$route.query.to,
                newBar: item.id
            }

            if (item) {
                if (item.productionType) {
                  item.elementType = item.productionType.toLowerCase();
                  // if (item.productionType == 'Event') item.elementType = 'event'
                  // if (item.productionType == 'Edit') item.elementType = 'edit'
                  // if (item.productionType == 'edit') item.elementType = 'edit'
                  // if (item.productionType == 'Task') item.elementType = 'task'
                  // if (item.productionType == 'task') item.elementType = 'task'
                  // if (item.productionType == 'Production') item.elementType = 'production'
                  // if (item.productionType == 'production') item.elementType = 'production'
                }
                let routeData = context.$router.resolve({name: `${item.elementType}.edit`, params: {id: item.id}, query: link_});
                window.open(routeData.href, '_blank');
            }
        },

        /**
         * Delete association in backend.
         * @param id
         */
        deleteItem(id) {
            const context = this
            swal({
                title: 'Remove?',
                text: `Are you sure you want to remove this association? Note that this doesn't remove the ${this.associationType} itself.`,
                type: 'question',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
            })
            .then(function() {
                context.$loader.add('BookAssociationsProcessing')
                const association = new Association(context.getApiUrl, id)
                association.delete()
                .then(response => {
                    context.removeAssociation(id)
                    context.$alert.set('The association was removed.', 'success', 4)
                    context.$loader.remove('BookAssociationsProcessing')
                })
                .catch(error => {
                    context.$error.set(error, 'It was not possible to remove the association.')
                    context.$loader.remove('BookAssociationsProcessing')
                })
            })
        },

        /**
         * Submit the selected item.
         */
        submit() {
          console.log(this.formData, 'hmm?')
          if (!this.formData.selection.value) return
          this.$loader.add('BookAssociationsProcessing')
          const association = new Association(this.getApiUrl, 0)

          if (this.getSelectedType === 'event') {
            this.formData.item.type = 'event'
            this.formData.item.association = this.formData.selection.value
            association.add(this.formData.item)
          } else {
            association.add({
              association: this.formData.selection.value,
              type: this.getSelectedType,
            })
          }

          association.store()
              .then(response => {
                Item.parse(response.data)
                console.log(response.data)
                this.updateAssociations(response.data)
                if(this.formData.addAnother.value) {
                  this.formData.selection.value = ''
                }
                else {
                  this.showForm(false)
                }

                this.$alert.set(`An association between ${this.type} ${this.parent.get.id} and ${response.data.elementType} (${response.data.id})`)
                this.$loader.remove('BookAssociationsProcessing')
              })
              .catch(error => {
                this.$error.set(error, 'It was not possible to save the association.')
                this.$loader.remove('BookAssociationsProcessing')
              })
        },

        /**
         * Add/update an association item on the current feed/production.
         * @param object item
         */
        updateAssociations(item) {
            const items = this.items.slice(0)
            let i = items.findIndex(v => v.id == item.id)
            if (i > -1) items.splice(i, 1)
            items.push(item)
            this.$emit('changedAssociations', items)
            this.parent.set('associations', items)
        },

        /**
         * Remove an association from the current feed/production.
         * @param integer id The id of the association.
         */
        removeAssociation(id) {
            const items = this.items.slice(0)
            let i = items.findIndex(v => v.associationId == id)
            if (i > -1) items.splice(i, 1)
            this.$emit('changedAssociations', items)
            this.parent.set('associations', items)
        },

        /**
         * Get the time string to be presented in the associated items list.
         * @param object item An association (feed/production) item.
         * @return string
         */
        getItemTime(item) {
            let start = moment(item[`${item.elementType}Start`])
            let end = moment(item[`${item.elementType}End`])
          if (item.elementType === 'event' && !item.event_start) return '-- - --'
          return start && end ? `${start.format('DD MMM, HH:mm')} - ${end.format('DD MMM, HH:mm')}` : ''
        },

        /**
         * Default data for add association form popup.
         */
        defaultData() {
            return {
                item: {
                  default: () => {
                    return {}
                  },
                  type: Object
                },
                type: {
                    value: '',
                    validator: 'required'
                },
                date: {
                    value: '',
                    validator: 'required'
                },
                league: {
                    value: ''
                },
                country: {
                    value: app.$data.user.get.country
                },
                selection: {
                    value: '',
                    validator: 'required'
                },
                addAnother: {
                    value: false
                }
            }
        }

    }
}

</script>

<template>
<div class="book-association">
    <button class="btn btn-success btn-xs book-button-add-association" @click="showForm()" :disabled="disabled" v-if="!showInSidebar">
        <font-awesome-icon icon="plus"/>&nbsp;Add
    </button>
    <book-loader :small="true" v-if="!loaded"></book-loader>
    <book-table
        v-if="loaded && !showInSidebar"
        :items="associationData"
        :component="'associations'"
        :itemsType="type"
        :notFoundMessage="!disabled || !disabledMessage ? 'No association has been found' : ''"
        @edit="item => openItem(item.id.value)"
        @delete="item => deleteItem(item.id.value)">
    </book-table>
    <book-table
        v-if="loaded && showInSidebar"
        :items="associationDataForSideBar"
        :component="'associations'"
        :itemsType="type"
        :notFoundMessage="!disabled || !disabledMessage ? 'No association has been found' : ''"
        @edit="item => openItem(item.id.value)">
    </book-table>
    
    <!--
    <div class="book-box-overlay" v-if="processing || disabled">
        <book-loader :small="true" v-if="processing"></book-loader>
        <span class="label label-danger" v-if="disabled" v-html="disabledMessage"></span>
    </div>-->
    <book-modal maxWidth="400px" @close="showForm(false)" v-if="addForm.show">
        <h4 slot="header" class="modal-title">Add association</h4>
        <div slot="body">
            <template v-if="!loaded">
                <div class="book-loading-text">Loading</div>
                <book-loader :small="true"></book-loader>
            </template>
            <div class="form-horizontal" v-else>
               <div class="form-group">
                    <label for="name" class="control-label book-width-100">Type</label>
                    <div class="form-control-100">
                        <select class="form-control" name="type" v-validate="formData.type.validator" v-model="formData.type.value">
                            <option value="">None</option>
                            <option value="feed">Feed</option>
                            <option value="production">Production</option>
                            <option value="event">Event</option>
                            <option value="edit">Edit</option>
                            <option value="task">Task</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label book-width-100">Start date</label>
                    <book-datetime
                        class="form-control-100"
                        component-id="date"
                        :required="{date: true}"
                        :hide-time="true"
                        :default-value="formData.date.value"
                        @newValue="value => formData.date.value = value">
                    </book-datetime>
                </div>
                <div class="form-group">
                     <label for="name" class="control-label book-width-100">League</label>
                     <div class="form-control-100">
                         <!-- <select class="form-control" name="league" v-model="formData.league.value">
                             <option value="">None</option>
                             <option v-for="league in leagueEntity.get.items" :value="league.id">{{league.label}}</option>
                         </select> -->
                         <book-selectbox
                                 componentId="league-id"
                                 :items="leagueEntity.get.items"
                                 :selected="formData.league.value"
                                 @selectedItem="id => formData.league.value = id">
                         </book-selectbox>
                     </div>
                </div>
                <div class="form-group">
                    <label for="country" class="control-label book-width-100">Country</label>
                    <div class="form-control-100">
                        <select class="form-control" name="country" v-model="formData.country.value" :disabled="!formData.type.value || loadingItems || (this.formData.country.value === '' && getItems.length == 0)">
                            <option value="">None</option>
                            <option v-for="country in countries" :value="country.code">{{country.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="control-label book-width-100">Item</label>
                    <div class="form-control-100">
                        <select class="form-control" name="selection" v-validate="formData.selection.validator" v-model="formData.selection.value" :disabled="!formData.type.value || loadingItems || getItems.length == 0">
                            <option value="">
                                <template v-if="getItems.length == 0 && !loadingItems">- Nothing found -</template>
                                <template v-else-if="loadingItems">- Searching... -</template>
                                <span v-else v-html="`- Select an item (${getItems.length}) -`"></span>
                            </option>
                            <option v-for="item in getItems" :value="getItemId(item)">{{getItemTitle(item)}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-control-100 checkbox">
                        <label class="control-label checkbox-inline" style="text-align: left">
                            <input type="checkbox" name="add_another" v-validate v-model="formData.addAnother.value" :value="true">
                            Add another
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <book-loader :small="true" v-if="loadingItems"></book-loader>
            <button class="btn btn-success" @click="submit()"
                    :disabled="processing || (formData.selection.value === '')"
            >Add</button>
        </div>
    </book-modal>
</div>
</template>

<style lang="scss">
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-association-wrapper {
    .panel-body {
        padding: 0;
        position: relative;
    }
    .book-button-add-association {
        position: absolute;
        right: 0;
        top: -27px;
    }
    .panel-heading ~ .book-association {
        .book-button-add-association {
            right: 3px;
            top: -25px;
        }
    }
}
.book-association {
    min-height: 40px;
    position: relative;
    > .book-loader {
        padding: 9px 0;
    }
    .modal-footer {
        .book-loader {
          float: left;
          margin-left: 5px;
          margin-top: 5px;
        }
    }
}
.panel-body > .book-association {
    margin: -8px;
}
</style>
